import React from 'react'
import {PageProps, Link} from 'gatsby'
import {withPrefix} from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import '../../static/Schoolgids KMS 2022-2023 - September 2022.pdf'

const Education = (props: PageProps) => (
  <Layout>
    <SEO title="Het onderwijs" />
    <p>
      Onze onderwijskundige doelen zijn gebaseerd op de kwaliteitscriteria die de Nederlandse overheid hanteert. Hierbij gaan wij uit van de
      kerndoelen zoals die geformuleerd zijn voor de deelvaardigheden van de Nederlandse taal: mondeling taalonderwijs, schriftelijk taalonderwijs en
      taalbeschouwing. Door de NOB zijn deze leerlijnen ‘vertaald’ in speciale leerlijnen voor het NTC onderwijs.
    </p>
    <p> Het NTC onderwijs onderscheidt drie richtingen:</p>
    <h5>NTC richting 1</h5>
    <p>
      Dit onderwijs is gericht op directe aansluiting bij onderwijs in Nederland en Vlaanderen en moet een terugkeer in het Nederlandse- of Vlaamse
      onderwijs faciliteren. Bij deze leerlingen is Nederlands de dominante taal in de thuissituatie; daarnaast spreken zij de dagschooltaal en/of de
      taal van het land.
    </p>
    <h5>NTC richting 2</h5>
    <p>
      Het doel is het zo goed mogelijk op peil houden van de Nederlandse taal, met het oog op een eventuele terugkeer naar Nederland of Vlaanderen.
      Bij deze leerlingen wordt doorgaans met één van de ouders Nederlands gesproken en de taal van de andere ouder komt meestal overeen met de
      dagschooltaal. Deze taal zal de dominante taal zijn binnen het gezin. Leerlingen binnen deze richting hebben een maximale leerachterstand van
      twee jaar op leeftijdgenoten in Nederland of Vlaanderen.
    </p>
    <h5>NTC richting 3</h5>
    <p>
      Deze leerlingen leren Nederlands als vreemde taal, met het oog op een eventuele verhuizing naar Nederland of Vlaanderen. Het betreft kinderen
      die thuis geen Nederlands spreken en de taal ook nog niet of nauwelijks beheersen. Deze leerlingen hebben een achterstand van meer dan twee jaar
      op leeftijdgenoten in Nederland of Vlaanderen.
    </p>
    <h5>Onderwijs op de Koningin Máxima School</h5>
    <p>
      De Koningin Máxima School verzorgt onderwijs in de NTC richtingen 1 en 2. In incidentele gevallen kunnen kinderen deelnemen die bij aanname het
      niveau van NTC richting 3 hebben.
    </p>
    {/* <p>
      <a href={withPrefix('Schoolgids KMS 2022-2023 - September 2022.pdf')} target="_blank" className="usefulLinks" rel="noopener">
        Schoolgids KMS 2022-2023
      </a>
    </p> */}
  </Layout>
)

export default Education
